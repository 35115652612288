<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <clipPath id="clip-path">
     <rect :fill="background" data-name="Rectangle 234" height="37.65" id="Rectangle_234" width="117.256"/>
    </clipPath>
   </defs>
   <g id="icon_halt" transform="translate(-2871 -2641)">
    <g data-name="Group 491" id="Group_491" transform="translate(429 23)">
     <g data-name="Group 469" id="Group_469" transform="translate(-228 263)">
      <rect :fill="background" data-name="Rectangle 186" height="183" id="Rectangle_186" transform="translate(2670 2355)" width="183"/>
     </g>
    </g>
    <path d="M15.851,11.18V22.843H8.476V11.18H0V42.935H8.476V28.648h7.375V42.935h8.476V11.18Z" data-name="Path 594" fill="#9c1a52" id="Path_594" transform="translate(2904 2708.105)"/>
    <g clip-path="url(#clip-path)" data-name="Group 492" id="Group_492" transform="translate(2904 2714)">
     <path d="M87.188,50.026V25.174H85.377L82.068,28.01a11.313,11.313,0,0,0-8.093-3.38C67.333,24.649,62,30.412,62,37.6s5.333,12.949,11.951,12.949a11.507,11.507,0,0,0,8.093-3.375l3.309,2.836ZM78.579,37.6c0,3.782-1.891,6.808-4.193,6.808s-4.141-3.054-4.141-6.808,1.891-6.808,4.141-6.808,4.193,3.049,4.193,6.808" data-name="Path 595" fill="#9c1a52" id="Path_595" transform="translate(-32.69 -12.986)"/>
    </g>
    <rect fill="#9c1a52" data-name="Rectangle 235" height="37.04" id="Rectangle_235" transform="translate(2965.613 2714)" width="8.476"/>
    <g clip-path="url(#clip-path)" data-name="Group 493" id="Group_493" transform="translate(2904 2714)">
     <path d="M171.516,41.377a9.727,9.727,0,0,1-9.928-10.136V21.815H156V20L168.23,7.24h1.811V16h9.332v5.81h-9.337v8.509c0,4.047,2,5.38,4.761,5.38a9.308,9.308,0,0,0,4.571-1.711V38.9c-1.522,1.57-5.569,2.472-7.852,2.472" data-name="Path 596" fill="#9c1a52" id="Path_596" transform="translate(-82.251 -3.817)"/>
    </g>
    <g clip-path="url(#clip-path)" data-name="Group 494" id="Group_494" transform="translate(2904 2714)">
     <path d="M230.425,67.492a5.446,5.446,0,1,1,5.427-5.47,5.5,5.5,0,0,1-5.441,5.47" data-name="Path 597" fill="#e53517" id="Path_597" transform="translate(-118.61 -29.842)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>